
import Vue from "vue";
import ASwitch from "@/components/elements/a-switch.vue";
import { Rq } from "@/lib/amdt";
import Api from "@/lib/api";
import WorgError from "@/lib/worg-error";
import worgErrorObject from "@/lib/worg-error-object";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";

export default Vue.extend({
  name: "DevSettings",

  components: { ASwitch, ABtnDialogConfirm },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      params: {
        trace: "false",
        debug: "false",
        info: "false",
        warn: "false",
        error: "false",
        fatal: "false",
      },
    };
  },

  computed: {},

  mounted() {
    this.getSettings().then();
  },

  methods: {
    async getSettings() {
      try {
        this.loading = true;

        const rq = new Rq("devsettings.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg =
            WorgError.getErrorByCode(this.ret_id)?.message ??
            worgErrorObject.Unknown.message;
          return;
        }

        this.params.trace = rsp.params.trace;
        this.params.debug = rsp.params.debug;
        this.params.info = rsp.params.info;
        this.params.warn = rsp.params.warn;
        this.params.error = rsp.params.error;
        this.params.fatal = rsp.params.fatal;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async evOnSubmit() {
      try {
        this.loading = true;

        const rq = new Rq("devsettings.write", this.params);
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
